import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row md:items-center md:justify-between md:gap-2 pt-2 pb-2 pl-4" }
const _hoisted_2 = { class: "flex flex-wrap items-center" }
const _hoisted_3 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "mx-1"
}
const _hoisted_9 = { class: "flex items-center justify-center gap-2 mt-2 md:mt-0 text-xs sm:text-base" }
const _hoisted_10 = { class: "flex flex-row gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbRoutes, (route, index) => {
        return (_openBlock(), _createElementBlock("span", { key: index }, [
          (index !== _ctx.breadcrumbRoutes.length - 1)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: route.path
              }, {
                default: _withCtx(() => [
                  (index === _ctx.breadcrumbRoutes.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(route.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.name), 1))
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                (index === _ctx.breadcrumbRoutes.length - 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(route.name), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.name), 1))
              ])),
          (index !== _ctx.breadcrumbRoutes.length - 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, "-"))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("aside", _hoisted_9, [
      _renderSlot(_ctx.$slots, "back", {}, () => [
        (_ctx.hasBack)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "flex border-2 border-success text-success rounded-lg p-1 hover:bg-green-100",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
            }, [
              _createElementVNode("strong", _hoisted_10, [
                _createVNode(_component_mdicon, { name: "backup-restore" }),
                _createTextVNode(" Volver a " + _toDisplayString(_ctx.breadcrumbRoutes[_ctx.breadcrumbRoutes.length - 2].name), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "body")
    ])
  ]))
}