
import { defineComponent, ref } from "vue";
import CardEmergency from "@/components/general/molecules/CardEmergency.vue";
import { useRouter, useRoute } from "vue-router";
import { TutorialService } from "@/services/tutorials/TutorialsServices";
import { TutorialVideo } from "@/services/tutorials/type";
import VideoModal from "@/components/tutorial/organisms/video/VideoModal.vue";
import TutorialViewModal from "@/components/tutorial/organisms/video/TutorialViewModal.vue";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";
import { useAuthStore } from "@/stores/auth";
import Header from "@/components/general/molecules/Header.vue";

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const authUser = authStore.user;
    const authUserCommunity = authStore.userCommunity;
    const router = useRouter();
    const route = useRoute();
    const tutorialService = new TutorialService();
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const videoTutorial = ref<TutorialVideo[]>([]);
    const ShowVideoModal = ref<boolean>(false);
    const action = ref<string>("");
    const showViewTutorialModal = ref<boolean>(false);
    const videoDataSend = ref<TutorialVideo[]>([]);
    const videoId = ref<number>(0);
    const title = ref<string>("");
    const message = ref<string>("");
    const success = ref<boolean>(false);
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/tutorial`,
        name: "Tutoriales",
      },
      { path: ``, name: "Videos" },
    ];
    const init = () => {
      const type = "TRAINING_VIDEOS";
      tutorialService
        .getTutorialType(communityId.value, type, blockId.value)
        .then((response) => {
          if (response.code === 200) {
            videoTutorial.value = response.data;
          }
        });
    };
    init();

    const openShowVideo = (actionSelect: string, videoData: any) => {
      videoDataSend.value = videoData.value;
      showViewTutorialModal.value = false;
      action.value = actionSelect;
      ShowVideoModal.value = true;
    };

    const closeModal = () => {
      ShowVideoModal.value = false;
      showViewTutorialModal.value = false;
      init();
    };

    const showVideoTutorial = (videoData: any) => {
      videoDataSend.value = videoData;
      showViewTutorialModal.value = true;
    };

    const deleteItem = (flatItemId: any) => {
      videoId.value = flatItemId;
      title.value = "¿Desea Eliminar?";
      message.value = "¿Está seguro que desea eliminar la comunidad?";
      success.value = true;
    };

    const handleAcceptSuccess = async () => {
      success.value = false;
      await tutorialService.deleteTutorial(communityId.value, videoId.value);
      init();
    };

    const handleCancelSuccess = () => {
      success.value = false;
    };

    return {
      videoTutorial,
      openShowVideo,
      closeModal,
      action,
      ShowVideoModal,
      showVideoTutorial,
      showViewTutorialModal,
      videoDataSend,
      deleteItem,
      handleAcceptSuccess,
      handleCancelSuccess,
      title,
      message,
      success,
      breadcrumbRoutes,
      authUser,
      authUserCommunity,
    };
  },
  components: { CardEmergency, VideoModal, TutorialViewModal, ModalAlert, Header },
});
