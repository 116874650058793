
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
interface BreadcrumbRoute {
  path: string;
  name: string;
}
export default defineComponent({
  props: {
    breadcrumbRoutes: {
      type: Array as () => BreadcrumbRoute[],
      required: true,
    },
    hasBack: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const back = () => {
      router.push(props.breadcrumbRoutes[props.breadcrumbRoutes.length - 2].path);
    };
    return { back };
  },
});
