
import TextField from '@/components/general/atoms/TextField.vue';
import Modal from '@/components/general/molecules/Modal.vue';
import { defineComponent, ref } from 'vue';
import { AuthService } from '@/services/auth/AuthServices';


export default defineComponent({

    props: {
        visible: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    emits: ["update:visible", "onClose"],
    
    setup() {

        const authServices = new AuthService();
        const hasError = ref(false)
        const errorMessage = ref("")
        const success = ref(false)
        const loadingResponse = ref(false);
        const email = ref("");

        async function handleForgorPassword() {
            hasError.value = false;
            loadingResponse.value = true;

            const response = await authServices.forgotPassword(email.value);
            loadingResponse.value = false;
            hasError.value = true;
            // Envia el correo de verificacion o verifica que el correo este registrado
            // Cambia el estado de success, hasError y errorMessage después de la consulta
        }

        return {
            email,
            hasError,
            errorMessage,
            success,
            handleForgorPassword

        };
    },
    components: { Modal, TextField }
})
