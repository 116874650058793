
import { defineComponent, ref, computed } from "vue";
import { Users } from "@/services/communities/types";
import TableComponent from "@/components/general/molecules/TableComponent.vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import AddButton from "@/components/general/atoms/AddButton.vue";
import { useRoute } from "vue-router";
import ModalAlert from "@/components/general/molecules/ModalAlert.vue";
import router from "@/router";
import CoOwnersModal from "@/components/queries/molecules/CoOwnersModal.vue";
import CoOwnersModalDetails from "@/components/queries/molecules/CoOwnersModalDetails.vue";
import Header from "@/components/general/molecules/Header.vue";
export default defineComponent({
  setup() {
    const coOwners = ref<Users[]>([]);
    const route = useRoute();
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const CommunityServices = new CommunityService();
    const showModal = ref<boolean>(false);
    const selectedEstablishment = ref<number>(0);
    const action = ref<string>("");
    const showModalEdit = ref<boolean>(false);
    const searchTableData = ref(["coOwnerName"]);
    const showButtons = computed(() => true);
    const numBlock = ref<string>("");
    const currentItem = ref<Users>();
    const showAlertModal = ref<boolean>(false);
    const showAlert = ref<boolean>(false);
    const message = ref<string>("");
    const loading = ref<boolean>(true);
    const selectedUnit = ref<string>("0");
    const showModalAlert = ref<boolean>(false);
    const title = ref<string>("");
    const iDinamic = ref<number>(0);
    const showOwnerDetail = ref<boolean>(false);
    const showUpdateAlert = ref<boolean>(false);
    const selectUser = ref<Users>();
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/queries`,
        name: "Consultas",
      },
      { path: "", name: "Nomina de Copropietarios" },
    ];
    const columns = [
      { name: "unit", label: "Unidad" },
      { name: "coOwnerName", label: "Nombre del copropietario" },
      { name: "email", label: "Correo electrónico" },
      { name: "phone", label: "Teléfono" },
      { name: "emergencyContactName", label: "Nombre de contacto de emergencia" },
      { name: "emergencyContactNumber", label: "Número de contacto de emergencia" },
    ];
    const formattedData = computed(() => {
      return coOwners.value.map((item) => {
        const data: any = {
          userId: item.userId,
          userRole: item.userRole,
          communityId: item.communityId,
          id: item.id,
          coOwnerName: item.user.firstName + " " + item.user.lastName,
          emergencyContactName: item.coOwnerEmergencyName,
          emergencyContactNumber: item.coOwnerEmergencyPhone,
          phone: item.user.phone,
          email: item.user.email,
          rut: item.user.dni,
          userInCommunity: item.id,
          floor:
            item.communityUserUnits && item.communityUserUnits.length > 0
              ? item.communityUserUnits[0].unit.floor
              : undefined,
          address: item.communityUserUnits.map((spot) => spot.unit.address).join(", "),
          unit: item.communityUserUnits.map((spot) => spot.unit.address).join(", "),
        };
        return data;
      });
    });

    async function getCommunityOwners() {
      await CommunityServices.getCoOwnersByBlock(communityId.value, blockId.value)
        .then((response) => {
          coOwners.value = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
      loading.value = false;
    }
    getCommunityOwners();
    function onUpdateModelUnit(value: string) {
      numBlock.value = value;
    }

    function onUpdateModelEstablishment(value: number) {
      selectedEstablishment.value = value;
    }

    function updateTable(item: any) {
      currentItem.value = coOwners.value.find((user) => user.id === item.id);
      showModal.value = true;
      showUpdateAlert.value = false;
      action.value = "update";
    }

    const deleteTable = async (registro: any) => {
      iDinamic.value = registro.id;
      title.value = "¿Desea Eliminar?";
      message.value = "No sera posible retroceder la Acción";
      showModalAlert.value = true;
    };
    const datailsTable = async (registro: any) => {
      selectUser.value = coOwners.value.find((user) => user.id === registro.id);
      showOwnerDetail.value = true;
    };
    const buttons = ref([
      {
        id: 1,
        name: "Editar",
        action: updateTable,
        className:
          " flex items-center justify-center bg-warning text-white hover:bg-yellow-400 focus:bg-yellow-400",
        iconName: "pencil",
      },
      {
        id: 2,
        name: "Eliminar",
        action: deleteTable,
        className: "bg-red-600 text-white hover:bg-red-500 focus:bg-red-500",
        iconName: "delete",
      },
      {
        id: 3,
        name: "Detalles",
        action: datailsTable,
        className: "bg-success text-white hover:bg-green-400 focus:bg-green-400",
        iconName: "eye-outline",
      },
    ]);
    function cancel() {
      showModal.value = false;
    }

    const handleCancel = () => {
      showModalAlert.value = false;
    };

    const handleDelete = async () => {
      await CommunityServices.deletePersonalService(communityId.value, iDinamic.value);
      showModalAlert.value = false;
      getCommunityOwners();
    };

    function addItem() {
      showModal.value = true;
      action.value = "create";
      console.log(showModal.value);
    }
    const handleAccept = () => {
      showModalAlert.value = false;
      showModal.value = false;
    };
    function create() {
      showModal.value = false;
      getCommunityOwners();
      if (action.value === "update") {
        showUpdateAlert.value = true;
        console.log();
      }
    }
    const handleCloseModal = () => {
      showOwnerDetail.value = false;
    };
    return {
      handleCloseModal,
      handleCancel,
      handleDelete,
      handleAccept,
      cancel,
      onUpdateModelUnit,
      onUpdateModelEstablishment,
      updateTable,
      addItem,
      create,
      formattedData,
      currentItem,
      action,
      showModal,
      columns,
      buttons,
      searchTableData,
      showButtons,
      selectedEstablishment,
      showModalEdit,
      showAlertModal,
      showAlert,
      message,
      loading,
      selectedUnit,
      title,
      showModalAlert,
      breadcrumbRoutes,
      showOwnerDetail,
      selectUser,
      showUpdateAlert,
    };
  },
  components: {
    TableComponent,
    ModalAlert,
    AddButton,
    CoOwnersModal,
    CoOwnersModalDetails,
    Header,
  },
});
