export const petTypes: any = [
  {
    value: "1",
    label: "Otro",
  },
  {
    value: "2",
    label: "Perro",
  },
  {
    value: "3",
    label: "Gato",
  },
  {
    value: "4",
    label: "Ave",
  },
  {
    value: "5",
    label: "Reptil",
  },
  {
    value: "6",
    label: "Anfibio",
  },
  {
    value: "7",
    label: "Pez",
  },
  {
    value: "8",
    label: "Roedor",
  },
];

export const establishments: any = [
  { value: 1, label: "Oficina" },
  { value: 2, label: "Residencia" },
  { value: 3, label: "Local Comercial" },
];
