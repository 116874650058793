
import { defineComponent, ref } from "vue";
import AdminBar from "@/components/queries/organisms/AdminBar.vue";
import { useRoute } from "vue-router";
import Header from "@/components/general/molecules/Header.vue";
export default defineComponent({
  setup() {
    const route = useRoute();
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      { path: ``, name: "Consultas" },
    ];
    return { breadcrumbRoutes };
  },
  components: { AdminBar, Header },
});
