
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AppButton from '@/components/general/atoms/AppButton.vue';
import { useAuthStore } from '../../stores/auth'
import { AuthService } from '@/services/auth/AuthServices'
import Selector from '@/components/general/atoms/Selector.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import Alert from '@/components/general/atoms/Alert.vue';

export default defineComponent({
    components: {
        AppButton,
        Selector,
        Alert
    },
    setup() {
        interface Community {
            value: any;
            label: any;
        }
        const communityService = new CommunityService();
        const router = useRouter();
        const authService = new AuthService();
        const authStore = useAuthStore();
        const communityId = ref<number>(0)
        const blockId = ref<number>(0)
        const showAlert = ref<boolean>(false)
        const type = ref<string>("")
        const message = ref<string>("")
        const buildingCommunities = ref<Community[]>([]);
        const buildingBlocks = ref<Community[]>([]);

        onMounted(async () => {
            init()
        });

        const init = async () => {
alert('en blocklist')
            buildingCommunities.value = []
            await communityService.getCommunitiesUser()
                .then((response) => {
                    if (response.code === 200) {
                        if (response.data.length > 0) {
                            response.data.forEach((obj: { id: any; name: any }) => {
                                const newCommunity = {
                                    value: obj.id,
                                    label: obj.name
                                };
                                buildingCommunities.value.push(newCommunity);
                            });
                        } else {
                            type.value = 'error';
                            message.value = 'Este usuario no tiene una comunidad asociada';
                            showAlert.value = true;
                        }
                        setTimeout(() => {
                            showAlert.value = false;
                        }, 4000);
                    }
                })
        };

        const obtainBlockId = async (value: any) => {
            buildingBlocks.value = []
            communityId.value = value;
            const authToken = authStore.userSession;
            if (authToken && communityId.value) {
                await communityService.getComunityUser(communityId.value, authToken?.userId)
                    .then((response) => {
                        if (response.code === 200) {
                            authStore.setCommunityUser(response.data)
                            communityService.getCommunityBlocks(response.data.communityId, response.data.id)
                                .then((response) => {
                                    if (response.code === 200) {
                                        if (response.data.length > 0) {
                                            response.data.forEach((obj: { id: any; name: any }) => {
                                                const newBlock = {
                                                    value: obj.id,
                                                    label: obj.name
                                                };
                                                buildingBlocks.value.push(newBlock);
                                            });
                                        }
                                    }
                                })

                        }
                    })
            } else {
                type.value = 'error';
                message.value = 'Usuario no registrado o sin comunidad asociada, redirigiendo a login';
                showAlert.value = true;
                router.push('/');

            }
        }

        const goHomePage = async () => {
            router.push({ path: `/community/${communityId.value}/block/${blockId.value}/home` });
        }

        const saveBlock = async (value: any) => {
            blockId.value = parseInt(value)
        }

        const back = async () => {
            const authToken = authStore.token as string;
            try {
                await authService.logout(authToken);
                authStore.logout();
                router.push('/');
            } catch (error) {
                console.error(error);
            }
        }

        return {
            back,
            obtainBlockId,
            communityId,
            blockId,
            showAlert,
            type,
            message,
            buildingCommunities,
            buildingBlocks,
            saveBlock,
            goHomePage,
        }
    }
})
