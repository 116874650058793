import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex sm:flex-col h-full items-center sm:justify-center flex-row" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col w-full h-full pb-full relative rounded-xl", { 'bg-gris': !_ctx.transparente, 'bg-transparente': _ctx.transparente }]),
    "data-transparente": "true"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("figure", {
        class: _normalizeClass(["flex m-2 sm:justify-center sm:items-center justify-start items-start", _ctx.isHome ? 'h-5/6 w-full ' : 'h-4/6 '])
      }, [
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.figureStyle),
          class: _normalizeClass(["flex items-center justify-center", _ctx.isHome?' h-full w-full' :'max-h-28 w-full h-full'])
        }, [
          (!_ctx.icon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "object-contain w-full h-full",
                src: _ctx.getImage(_ctx.figure),
                alt: "My Image",
                loading: "lazy"
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_mdicon, {
                key: 1,
                name: _ctx.icon,
                size: _ctx.dynamicIconSize,
                class: "flex ml-4"
              }, null, 8, ["name", "size"]))
            : _createCommentVNode("", true)
        ], 6)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col justify-center sm:items-center w-full items-center", _ctx.isHome ? 'h-1/6' : 'h-2/6 '])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(`flex text-black  font-normal text-center text-${_ctx.dynamicTextSize}`)
        }, _toDisplayString(_ctx.title), 3)
      ], 2)
    ])
  ], 2))
}