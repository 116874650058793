
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import CardEmergency from "@/components/general/molecules/CardEmergency.vue";
import Header from "@/components/general/molecules/Header.vue";
export default defineComponent({
  setup(props, { emit }) {
    const eventDate = [
      { id: 1, figure: 'emergency/fire_emergency', title: '', styleCard: 'bg-danger ', isSvg: true },
      { id: 3, figure: 'emergency/tsunami_emergency', title: '', styleCard: 'bg-info', isSvg: true },
      { id: 2, figure: 'emergency/earthquake_emergency', title: '', styleCard: 'bg-orangeE', isSvg: true },
      { id: 4, figure: 'emergency/exit_emergency', title: '', styleCard: 'bg-success', isSvg: true },
    ]

    const route = useRoute();
    const router = useRouter();
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const emergencyId = ref<number>(
      typeof route.params.emergencyId === "string"
        ? parseInt(route.params.emergencyId)
        : 0
    );
    const emergencyTypeId = ref<number>(
      typeof route.params.emergencyTypeId === "string"
        ? parseInt(route.params.emergencyTypeId)
        : 0
    );
    const instructionName = ref<string>(
      emergencyTypeId.value === 1
        ? "Incendio"
        : emergencyTypeId.value === 3
        ? "Tsunami"
        : emergencyTypeId.value === 2
        ? "Terremoto"
        : "Evacuacion"
    );
    const emergencyTabName = ref<string>(emergencyId.value == 5 ? "Simulacro" : "");
    const selectEvent = eventDate.find((event) => event.id === emergencyTypeId.value);
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/emergency`,
        name: "Emergencias",
      },
      { path: "", name: instructionName.value },
    ];
    const initializerInstruction = () => {
      router.push({
        path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/instruction`,
      });
    };

    const initializerEmergency = () => {
      router.push({
        path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/success`,
      });
    };
    const initializerSimulacrum = () => {
      router.push({
        path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/success/simulacrum`,
      });
    };

    return {
      emergencyId,
      initializerInstruction,
      instructionName,
      initializerEmergency,
      emergencyTabName,
      initializerSimulacrum,
      selectEvent,
      breadcrumbRoutes,
    };
  },

  components: { CardEmergency, Header },
});
