
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { AuthService } from "@/services/alert/service";
import { AlertCreate } from "@/services/alert/types";
import CardEmergency from "@/components/general/molecules/CardEmergency.vue";
import Header from "@/components/general/molecules/Header.vue";
export default defineComponent({
  props: {
    simulacrum: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const authService = new AuthService();
    const router = useRouter();
    const alertCreate = ref<AlertCreate>();
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const emergencyId = ref<number>(
      typeof route.params.emergencyId === "string"
        ? parseInt(route.params.emergencyId)
        : 0
    );
    const emergencyTypeId = ref<number>(
      typeof route.params.emergencyTypeId === "string"
        ? parseInt(route.params.emergencyTypeId)
        : 0
    );
    const instructionName = ref<string>(
      emergencyTypeId.value === 1
        ? "Incendio"
        : emergencyTypeId.value === 3
        ? "Tsunami"
        : emergencyTypeId.value === 2
        ? "Terremoto"
        : "Evacuacion"
    );
    const emergencyTabName = ref<string>(emergencyId.value == 5 ? "Simulacro" : "");
    const eventDate = [
      { id: 1, figure: "fire_emergency", title: "", styleCard: "bg-white" },
      { id: 3, figure: "tsunami_emergency", title: "", styleCard: "bg-info" },
      { id: 2, figure: "earthquake_emergency", title: "", styleCard: "bg-orangeE" },
      { id: 4, figure: "exit_emergency", title: "", styleCard: "bg-success" },
    ];
    const selectEvent = eventDate.find((event) => event.id === emergencyTypeId.value);

    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/emergency`,
        name: "Emergencias",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}`,
        name: instructionName.value,
      },
      { path: "", name: "Iniciar Alarma" },
    ];

    const goStep = async () => {
      let alert: AlertCreate = {
        emergencyTypeId: emergencyId.value,
        isDrill: props.simulacrum,
      };

      let response = authService.createAlert(communityId.value, alert);
      if (props.simulacrum) {
        router.push({
          path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/step/1/simulacrum`,
        });
      } else {
        router.push({
          path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${emergencyTypeId.value}/step/1`,
        });
      }
    };

    return {
      emergencyId,
      instructionName,
      emergencyTabName,
      goStep,
      breadcrumbRoutes,
      selectEvent,
    };
  },

  components: { CardEmergency, Header },
});
