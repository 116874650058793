
import { defineComponent, ref } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import ComponentCard from '../../general/molecules/ComponentCard.vue';
import { useRoute, useRouter } from 'vue-router';
import ContactCardComponent from '../atoms/ContactCardComponent.vue';
import EmergencyModal from '@/components/queries/molecules/emergency/EmergencyModal.vue';
import { EmergencyTypeService } from '@/services/emergencyType/EmergencyTypeServices';
import { EmergencyTypeInfo } from "@/services/emergencyType/types";
import EmergencyRegisterModal from '@/components/queries/molecules/emergency/EmergencyRegisterModal.vue';

export default defineComponent({
    setup() {

        const router = useRouter();
        const route = useRoute();
        const emergencyTypeService = new EmergencyTypeService();
        const showModal = ref<boolean>(false);
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0);
        const carabineros = ref<EmergencyTypeInfo[]>([]);
        const bomberos = ref<EmergencyTypeInfo[]>([]);
        const investigation = ref<EmergencyTypeInfo[]>([]);
        const emergency = ref<EmergencyTypeInfo[]>([]);
        const security = ref<EmergencyTypeInfo[]>([]);
        const emergecyNumbers = ref<EmergencyTypeInfo[]>([]);
        const sendDataEmergency = ref<EmergencyTypeInfo[]>([]);
        const updatesendDataEmergency = ref<EmergencyTypeInfo>();
        const typeDate = ref<string>('');
        const actionResponse = ref<string>('')
        const emergencyTypeResponse = ref<string>('')
        const showRegister = ref<boolean>(false)
        const loading = ref<boolean>(true)
        const eventMenu = [
            { id: 0, figure: 'queries/account-multiple', title: 'Nómina de residentes', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 14, figure: 'queries/account-multiple', title: 'Nómina de copropietarios', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' }, { id: 1, figure: 'queries/account-question', title: 'Preguntas frecuentes', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 2, figure: 'queries/book-lock', title: 'Seguro de incendio', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 3, figure: 'queries/office-building-plus', title: 'Antecedentes edificio', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 4, figure: 'queries/car-brake-alert', title: ' Empadronamiento y registros', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 5, figure: 'queries/account-lock', title: 'Administración y comité', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 6, figure: 'queries/calendar-text-outline', title: 'Contratistas Autorizados', styleCard: 'bg-success bg-gradient-to-b from-cardMenu ' },
            { id: 12, figure: 'tutorials/list-box-outline', title: 'Reglamentos', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
            { id: 13, figure: 'tutorials/floor-plan', title: 'Planos', styleCard: 'bg-success bg-gradient-to-b from-cardMenu text-4xl' },
        ]

        const init = async () => {
            await emergencyTypeService.getEmergencyTypeIntitution(communityId.value, blockId.value)
                .then((response) => {
                    emergecyNumbers.value = response.data
                })
            carabineros.value = emergecyNumbers.value.filter((item, index, self) => {
                return item.institution === 'CARABINEROS' && self.findIndex((t) => t.id === item.id) === index;
            });

            bomberos.value = emergecyNumbers.value.filter((item, index, self) => {
                return item.institution === 'BOMBEROS' && self.findIndex((t) => t.id === item.id) === index;
            });

            investigation.value = emergecyNumbers.value.filter((item, index, self) => {
                return item.institution === 'INVESTIGACION' && self.findIndex((t) => t.id === item.id) === index;
            });

            emergency.value = emergecyNumbers.value.filter((item, index, self) => {
                return item.institution === 'AMBULANCIA' && self.findIndex((t) => t.id === item.id) === index;
            });

            security.value = emergecyNumbers.value.filter((item, index, self) => {
                return item.institution === 'SEGURIDAD' && self.findIndex((t) => t.id === item.id) === index;
            });
            loading.value = false

        }

        init()

        async function routerPath(index: number) {
            showModal.value = true;
            switch (index) {
                case 0:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/residents` });
                    break;
                case 1:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/questions` });
                    break;
                case 2:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/fireInsurance` });
                    break;
                case 3:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/buildingBackground` });
                    break;
                case 4:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/census` });
                    break;
                case 5:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/committe` });
                    break;
                case 6:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/contractors` });
                    break;
                case 7:
                    sendDataEmergency.value = carabineros.value
                    typeDate.value = 'Carabineros'
                    showModal.value = true;
                    break;
                case 8:
                    sendDataEmergency.value = bomberos.value
                    typeDate.value = 'Bomberos'
                    showModal.value = true;
                    break;
                case 9:
                    sendDataEmergency.value = investigation.value
                    typeDate.value = 'Investigación'
                    showModal.value = true;
                    break;
                case 10:
                    sendDataEmergency.value = emergency.value
                    typeDate.value = 'Ambulancia'
                    showModal.value = true;
                    break;
                case 11:
                    sendDataEmergency.value = security.value
                    typeDate.value = 'Seguridad'
                    showModal.value = true;
                    break;
                case 12:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/reglamentos` });
                    break;
                case 13:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/planos` });
                    break;
                case 14:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/queries/co-owners` });
                    break


            }
        }

        const closeModal = () => {
            showModal.value = false
            showRegister.value = false
            init()
        }


        const actionEmergency = (action: any, emergencyType: any, sendDataEmergency: any) => {
            closeModal()
            actionResponse.value = action
            emergencyTypeResponse.value = emergencyType
            updatesendDataEmergency.value = sendDataEmergency
            showRegister.value = true
        }


        return {
            routerPath,
            eventMenu,
            closeModal,
            showModal,
            typeDate,
            sendDataEmergency,
            actionEmergency,
            actionResponse,
            emergencyTypeResponse,
            showRegister,
            updatesendDataEmergency,
            loading
        };
    },
    components: { ComponentCard, CardEmergency, ContactCardComponent, EmergencyModal, EmergencyRegisterModal },

})
