
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { AuthService } from '@/services/auth/AuthServices'
import { useAuthStore } from '@/stores/auth';
import TextField from '@/components/general/atoms/TextField.vue'
import RecoverAccount from '@/components/communities/molecules/RecoverAccount.vue';
import AppButton from '@/components/general/atoms/AppButton.vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import Alert from '@/components/general/atoms/Alert.vue';

export default defineComponent({
    components: {
        TextField,
        RecoverAccount,
        AppButton,
        Alert
    },
    setup() {
        const communityService = new CommunityService()
        const router = useRouter();
        const authService = new AuthService();
        const authStore = useAuthStore();
        const email = ref("")
        const password = ref("")
        const hasError = ref(false)
        const showPassword = ref(false)
        const loading = ref(false)
        const errorMessage = ref("Credenciales inválidas")
        const recoverPassword = ref(false)
        const showAlert = ref<boolean>(false)
        const type = ref<string>("")
        const message = ref<string>("")
        function showPass() {
            showPassword.value = !showPassword.value
        }
        async function logout() {
            loading.value = true;
            const authToken = authStore.token as string;
            try {
                const response = await authService.logout(authToken);
                if (response.code == 204) {
                    authStore.logout();
                    router.push('/');
                }
            } catch (error) {
                console.error(error);
                hasError.value = true;
            } finally {
                loading.value = false;
            }
        }
        async function login() {
            hasError.value = false
            loading.value = true
            const response = await authService.login(email.value, password.value)
            loading.value = false
            if (!response.success) {
                hasError.value = true
                return
            } else {
                authStore.setToken(response.data.token)
                authStore.setUserSession(response.data)
                const communities = await communityService.getCommunitiesUser()
                if (communities.data.length != 0) {
                    console.log(communities.data.length)
                    if (communities.data.length === 1) {
                        const communityUser = await communityService.getComunityUser(communities.data[0].id, response.data.userId)
                        // if (communityUser.data.userRole === 1) {
                            //     type.value = 'error';
                            //     message.value = 'Este usuario no tiene los privilegios necesarios para acceder';
                            //     showAlert.value = true;
                            //     logout()
                            //     return
                            // }
                            authStore.setCommunityUser(communityUser.data)
                            const comunnityBlocksUser = await communityService.getCommunityBlocks(communityUser.data.communityId, communityUser.data.id)
                            if (comunnityBlocksUser.data.length > 0) {
                                return comunnityBlocksUser.data.length === 1 ? router.push({ name: 'home', params: { id: comunnityBlocksUser.data[0].id, community: communities.data[0].id } }) : router.push('block-list')
                            } else {
                                return router.push({ name: 'home', params: { id: 0, community: communities.data[0].id } })
                            }
                        } else {
                        
                        alert('va a blocklist')
                        return router.push('block-list')
                    }
                } else {
                    type.value = 'error';
                    message.value = 'Este usuario no tiene una comunidad asociada';
                    showAlert.value = true;
                } setTimeout(() => {
                    showAlert.value = false;
                }, 4000);

            }
        }

        function showRecoverPasswordModal() {
            recoverPassword.value = true
        }

        function goToRegisterView() {
            router.push({ name: "register" })
        }

        return {
            recoverPassword,
            email,
            loading,
            password,
            errorMessage,
            hasError,
            login,
            showRecoverPasswordModal,
            goToRegisterView,
            type,
            showPass,
            showPassword,
            message,
            showAlert
        }
    }
})
