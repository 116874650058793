
import { defineComponent, ref, computed } from 'vue';
import { CommunityService } from '@/services/communities/CommunityServices';
import { Users } from '@/services/communities/types'
import TableComponent from '@/components/general/molecules/TableComponent.vue';
import { useRoute } from 'vue-router';
import Modal from '@/components/general/molecules/Modal.vue'



export default defineComponent({
    emit: ["update:visible", "update"],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const route = useRoute();
        const currentItem = ref<Users>();
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0);
        const CommunityServices = new CommunityService();
        const userDiscapacities = ref<Users[]>([]);
        const showModal = ref(props.visible)
        const columns = ref([{ name: 'firsName', label: 'Nombre' }, { name: 'lastName', label: 'Apellido' }, { name: 'reducedCapacityDetails', label: 'Capacidad Reducida' }, { name: 'age', label: 'Edad' }, { name: 'profession', label: 'Profesión' }, { name: 'position', label: 'Cargo' }, { name: 'phone', label: 'Teléfono' }, { name: 'email', label: 'Email' },
        { name: 'rut', label: 'DNI' }, { name: 'parkingSpace', label: 'Estacionamiento' }, { name: 'warehouseSpace', label: 'Almacen' }
            , { name: 'floor', label: 'Piso' }, { name: 'address', label: 'Dirección' }]);

        async function getUserDiscapacities() {
            await CommunityServices.getResidentReduceCapacity(communityId.value)
                .then((response) => {
                    userDiscapacities.value = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
            console.log("data", userDiscapacities.value)
        }

        getUserDiscapacities()

        const formattedData = computed(() => {
            return userDiscapacities.value.map((item) => {
                return {
                    userId: item.userId,
                    position: item.position,
                    parkingSpace: item.parkingSpace,
                    warehouseSpace: item.warehouseSpace,
                    userRole: item.userRole,
                    isActive: item.isActive,
                    isCommitteMember: item.isCommitteeMember,
                    committePosition: item.committeePosition,
                    isFab: item.isFab,
                    communityId: communityId.value,
                    id: item.id,
                    firsName: item.user.firstName,
                    lastName: item.user.lastName,
                    profession: item.user.profession,
                    age: item.user.age,
                    phone: item.user.phone,
                    hasReducedCapacity: item.user.hasReducedCapacity,
                    reducedCapacityDetails: item.user.reducedCapacityDetails,
                    email: item.user.email,
                    rut: item.user.dni,
                    userInCommunity: item.id,
                    floor: item.communityUserUnits && item.communityUserUnits.length > 0 ? item.communityUserUnits[0].unit.floor : undefined,
                    address: item.communityUserUnits && item.communityUserUnits.length > 0 ? item.communityUserUnits[0].unit.address : undefined,
                    idUnit: ref<number>(),

                }
            })
        })

        const buttons = ref([
        ])
        function cancel() {
            currentItem.value = {} as Users
            showModal.value = false
        }

        const closeModal = () => {
            console.log('cerrando 1');

            emit('closeModal')
        }

        return {
            formattedData,
            columns,
            buttons,
            showModal,
            currentItem,
            closeModal,
            cancel,
        }
    },
    components: { TableComponent, Modal }
})
