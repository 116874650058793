import { Response } from "../types";
import {
  GasCylinderBase,
  GasCylinder,
  GasCylinderUpdate,
  FireExtinguisherBase,
  FireExtinguisherUpdate,
  communitySeviceConstractor,
  WorkerInfo,
  userInfo,
  CommitteUserUnit,
  Service,
  RegisterCompensation,
  userCreate,
  updateUsers,
  certificationsBase,
  certificationUpdate,
  insurance,
  updateInsurance,
  UpdateCommunityBlock,
  updateUsersDiminished,
  updateUserCommitte,
} from "./types";
import { AuthenticatedService } from "../auth/AuthenticatedServices";
import {
  getResponse,
  keysToSnake,
  fetchDownloadFileBodyRequired,
  fetchGetFileBodyRequired,
} from "../utils";
import { getEnvConfig } from "@/configs/UrlConfig";

export class CommunityService extends AuthenticatedService {
  async getCommunitiesUser() {
    const url = `${getEnvConfig().apiURL}/community/users/my-communities`;
    return getResponse(url, this.token, "GET");
  }

  async getComunityUser(communityId: number, userId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-user/${userId}`;
    return getResponse(url, this.token, "GET");
  }

  async updateUsersCommunity(
    communityId: number,
    communityUserId: number,
    user: updateUsers
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/residents/${communityUserId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(user));
  }

  // Had to make a new endpoint due to time pressure and for preventing breaking things that use the previous endppint
  async editUsersCommunity(
    communityId: number,
    communityUserId: number,
    user: updateUsers
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/residents/${communityUserId}/edit`;
    return getResponse(url, this.token, "POST", keysToSnake(user));
  }

  async updateUsersDiminishedCapacities(
    communityId: number,
    communityUserId: number,
    user: updateUsersDiminished
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/residents/${communityUserId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(user));
  }

  async updateUserCommitte(
    
    communityId: number,
    communityUserId: number,
    user: updateUserCommitte
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/residents/${communityUserId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(user));
  }

  async getCommunityBlocks(communityId: number, userInCommunityId: number) {
    console.log('adsd');
    
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community_user/${userInCommunityId}/blocks`;
    return getResponse(url, this.token, "GET");
  }
  async updateCommunityBlock(
    communityId: number,
    blockId: number,
    block: UpdateCommunityBlock
  ) {
    // /community/{community_id}/block/{block_id}/update
    const form = new FormData();
    form.append("name", block.name);
    form.append("address", block.address);
    form.append("total_floors", block.totalFloors.toString());
    form.append("total_units", block.totalUnits.toString());
    form.append(
      "total_visitor_parking_spots",
      block.totalVisitorParkingSpots.toString()
    );
    form.append("total_parking_spots", block.totalParkingSpots.toString());
    form.append(
      "total_warehouse_spaces",
      block.totalWarehouseSpaces.toString()
    );
    form.append(
      "total_commercial_premises",
      block.totalCommercialPremises.toString()
    );
    form.append(
      "total_undeground_levels",
      block.totalUndegroundLevels.toString()
    );
    form.append("gas_type", block.gasType);
    form.append(
      "municipal_acceptance_date",
      block.municipalAcceptanceDate.toISOString()
    );
    form.append("construction_year", block.constructionYear.toString());
    form.append("structural_calculations", block.structuralCalculations);
    form.append("construction_company", block.constructionCompany);
    form.append("real_state_agency", block.realStateAgency);
    form.append("rut", block.rut);
    form.append("company_name", block.companyName);
    form.append("community_id", block.communityId.toString());
    form.append("block_id", block.blockId.toString());
    form.append("is_submersible", block.isSubmersible ? '1' : '0');

    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}/update`;
    return getResponse(url, this.token, "POST", undefined, form);
  }
  async getBlockCommunity(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/blocks`;
    return getResponse(url, this.token, "GET");
  }

  async getUnits(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/units`;
    return getResponse(url, this.token, "GET");
  }
  async getQueriesByCommunity(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/queries`;

    return getResponse(url, this.token, "GET");
  }
  async getQueries() {
    const url = `${getEnvConfig().apiURL}/queries/all`;
    return getResponse(url, this.token, "GET");
  }
  async createFireExtinguisher(
    communityId: number,
    blockId: number,
    fireExtinguisher: FireExtinguisherBase
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/fire-extinguishers/create`;
    return getResponse(url, this.token, "POST", keysToSnake(fireExtinguisher));
  }

  async getFireExtinguishers(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/fire-extinguishers`;
    return getResponse(url, this.token, "GET");
  }
  async updateFireExtinguisher(
    communityId: number,
    blockId: number,
    extinguisherId: number,
    fireExtinguisher: FireExtinguisherUpdate
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/fire-extinguishers/${extinguisherId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(fireExtinguisher));
  }
  async deleteFireExtinguisher(
    communityId: number,
    blockId: number,
    fireExtinguisherId: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/fire-extinguishers/${fireExtinguisherId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }
  async getGasCylindersByBlock(
    communityId: number,
    blockId: number
  ): Promise<Response<GasCylinder[]>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/gas-cylinders/all`;
    return getResponse(url, this.token, "GET");
  }
  async createGasCylinder(
    communityId: number,
    unitId: number,
    gasCylinder: GasCylinderBase
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/units/${unitId}/gas-cylinders/create`;
    return getResponse(url, this.token, "POST", keysToSnake(gasCylinder));
  }
  async updateGasCylinder(
    communityId: number,
    blockId: number,
    gasCylinderId: number,
    gasCylinder: GasCylinderUpdate
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/gas-cylinders/${gasCylinderId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(gasCylinder));
  }
  async deleteGasCylinder(
    communityId: number,
    block_id: number,
    gasCylinderId: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${block_id}/gas-cylinders/${gasCylinderId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }

  async getServicesCommunity(
    communityId: number
  ): Promise<Response<communitySeviceConstractor[]>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-services/all`;
    return getResponse(url, this.token, "GET");
  }

  async getInsurance(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/all`;
    return getResponse(url, this.token, "GET");
  }
  async getInsuranceFile(
    communityId: number,
    insuranceId: number
  ): Promise<Blob> {
    const fileName = "insurance.pdf";
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/${insuranceId}/document`;
    return fetchGetFileBodyRequired(url, this.token, "POST", fileName);
  }
  async createInsurance(communityId: number, insurance: insurance) {
    const form = new FormData();
    if (insurance.document) {
      form.append("document", insurance.document);
    }
    form.append("name", insurance.name);
    form.append("address", insurance.address);
    form.append("phone", insurance.phone);
    form.append("policy_number", insurance.policyNumber);
    form.append("expiration_date", insurance.expirationDate);
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/create`;
    return getResponse(url, this.token, "POST", undefined, form);
  }
  async updateInsurance(
    communityId: number,
    insuranceId: number,
    insurance: updateInsurance
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/${insuranceId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(insurance));
  }
  async deleteInsurance(communityId: number, insuranceId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/${insuranceId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }
  async uploadInsuranceFile(
    communityId: number,
    insuranceId: number,
    file: File
  ) {
    const form = new FormData();
    form.append("document", file);
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/${insuranceId}/upload`;
    return getResponse(url, this.token, "POST", undefined, form);
  }

  async getVehicles(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/vehicles`;
    return getResponse(url, this.token, "GET");
  }

  async getUserCommunity(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/vehicles`;
    return getResponse(url, this.token, "GET");
  }

  async updateVehicles(communityId: number, vehicleDate: any) {
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/vehicles/${vehicleDate.vehicleId}/edit`;
    return getResponse(url, this.token, "POST", vehicleDate);
  }
  async getUser(communityId: number,  userComunityId: any) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-users/${userComunityId}/info`;
    return getResponse(url, this.token, "GET");
  }
  async createVehicle(communityId: number, vehicleDate: any) {
    const params = {
      community_user_id: vehicleDate.communityUserId,
      patent: vehicleDate.patent,
    };

    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/authorized-vehicles/create`;
    return getResponse(url, this.token, "POST", params);
  }
  async deleteVehicles(communityId: number, vehicleId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community-extras/${communityId}/vehicles/${vehicleId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }

  async getResidentReduceCapacity(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/residents/reduced-capacity`;
    return getResponse(url, this.token, "GET");
  }
  async getResident(communityId: number) {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/residents`;
    return getResponse(url, this.token, "GET");
  }
  async getResidentByBlock(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/residents`;
    return getResponse(url, this.token, "GET");
  }
  async getCoOwnersByBlock(communityId: number, blockId: number) {
    const url = `${getEnvConfig().apiURL
      }/community/${communityId}/blocks/${blockId}/coowners`;
    return getResponse(url, this.token, "GET");
  }


  async createResidentInCommunity(communityId: number, resident: userCreate) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/register-and-join`;
    return getResponse(url, this.token, "POST", keysToSnake(resident));
  }

  // Take care, this endpoint has a similar purpose as the one above, but the difference is the way the data is being sent, pets
  // array are inside each unit.
  async createNewResidentInCommunity(communityId: number, resident: object) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/register-and-join-new`;
    return getResponse(url, this.token, "POST", keysToSnake(resident));
  }

  async getCommitteUserAll(communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-committee`;
    return getResponse(url, this.token, "GET");
  }

  async getCommitteUser(
    communityId: number,
    userId: number
  ): Promise<Response<userInfo>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/users/${userId}`;
    return getResponse(url, this.token, "GET");
  }

  async getPersonal(communityId: number): Promise<Response<userInfo>> {
    const url = `${getEnvConfig().apiURL}/community/${communityId}/personnel`;
    return getResponse(url, this.token, "GET");
  }

  async createPersonal(
    communityId: number,
    personnel: WorkerInfo
  ): Promise<Response<WorkerInfo>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/register-personnel`;
    return getResponse(url, this.token, "POST", keysToSnake(personnel));
  }

  async updatePersonal(
    communityId: number,
    communityUserId: number,
    personnel: WorkerInfo
  ): Promise<Response<WorkerInfo>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/personnel/${communityUserId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(personnel));
  }

  async deletePersonalService(communityId: number, communityUserId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-user/${communityUserId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }

  getBlockById(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/block/${blockId}`;
    return getResponse(url, this.token, "GET");
  }

  async getServicesCommunityAllType(
    communityId: number,
    serviceType: string
  ): Promise<Response<communitySeviceConstractor[]>> {
    const params = {
      service_type: serviceType,
    };
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-services/all-type`;
    return getResponse(url, this.token, "POST", params);
  }

  async getOneService(
    communityId: number,
    servicId: number
  ): Promise<Response<RegisterCompensation>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-services/${servicId}`;
    return getResponse(url, this.token, "GET");
  }

  async createService(
    communityId: number,
    service: RegisterCompensation
  ): Promise<Response<RegisterCompensation>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-services/create`;
    return getResponse(url, this.token, "POST", keysToSnake(service));
  }

  updateService(
    communityId: number,
    serviceId: number,
    service: RegisterCompensation
  ): Promise<Response<RegisterCompensation>> {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-services/${serviceId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(service));
  }
  getUnitsByBlock(blockId: number, communityId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/units`;
    return getResponse(url, this.token, "GET");
  }
  getCertificationsByBlock(communityId: number, blockId: number) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/certifications/all`;
    return getResponse(url, this.token, "GET");
  }
  createCertification(
    communityId: number,
    blockId: number,
    certification: certificationsBase
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/certifications/create`;
    return getResponse(url, this.token, "POST", keysToSnake(certification));
  }
  updateCertification(
    communityId: number,
    blockId: number,
    certificationId: number,
    certification: certificationUpdate
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/certifications/${certificationId}/update`;
    return getResponse(url, this.token, "POST", keysToSnake(certification));
  }
  deleteCertification(
    communityId: number,
    blockId: number,
    certificationId: number
  ) {
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/blocks/${blockId}/certifications/${certificationId}/delete`;
    return getResponse(url, this.token, "DELETE");
  }
  async downloadInsuranceFile(communityId: number, insuranceId: number) {
    const fileName = `insurance${insuranceId}.pdf`;
    const url = `${
      getEnvConfig().apiURL
    }/community/${communityId}/community-insurance/${insuranceId}/document`;
    return fetchDownloadFileBodyRequired(url, this.token, "POST", fileName);
  }
  async getResidentByCoOwner(communityId: number, coownerId: number) {
      const url = `${getEnvConfig().apiURL}/community/${communityId}/coowner/${coownerId}/get`;
      return getResponse(url, this.token, "GET");
  }

}
