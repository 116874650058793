<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
    required: true,
  },
  selectedItem: {
    type: [Number, String],
    default: 0,
  },
  label: {
    type: String,
    default: "",
  },
  hasError: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: "",
  },
  itsDisabled: {
    type: Boolean,
    default: false,
  },
  placeHolder: {
    type: String,
    default: "Seleccione una opción",
  },
  backGround: {
    type: String,
    default: "",
  },
  borderColor: {
    type: String,
    default: "",
  },
  textColor: {
    type: String,
    default: "",
  },
  fountText: {
    type: String,
    default: "",
  },
  stringTypeValue:{
    type:Boolean,
    default:false,
  }
});

const emit = defineEmits(["update"]);

const selectedItems = ref(props.selectedItem);

watch(
  () => props.selectedItem,
  (newValue) => {
    selectedItems.value = newValue;
  }
);

function onChange(event) {
  selectedItems.value = !props.stringTypeValue ? parseInt(event.target.value) : event.target.value;
  emit("update", selectedItems.value);
}

</script>

<template>
  <div class="flex flex-col gap-1 selector w-full">
    <label
      v-if="label"
      class="flex flex-row items-center text-xs"
      for="selection"
      :class="{ 'text-red-500': hasError, 'text-gray-700': !hasError }"
    >
      {{ label }}
      <div class="text-red-500 ml-auto flex place-items-center" v-if="hasError">
        <Popper
          arrow
          class="font-normal"
          placement="auto"
          hove
          :content="errorMessage"
        >
          <mdicon name="error" size="16" />
        </Popper>
      </div>
    </label>
    <div class="relative w-full">
      <div class="flex flex-row items-center relative select-none">
        <select
          v-model="selectedItems"
          :disabled="itsDisabled"
          @change="onChange"
          id="selection"
          class="appearance-none border rounded-lg w-full py-1 px-2 h-9 leading-tight focus:outline-none cursor-pointer"
          :class="{
            [backGround]: backGround,
            'border-gray-300': !hasError,
            'text-red-700 border-red-300 focus:border-red-800 hover:border-red-400':
              hasError,
          }"
        >
          <option value="0" disabled selected hidden>{{ placeHolder }}</option>
          <option
            :class="{ [backGround]: backGround }"
            v-for="item in items"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </option>
        </select>
        <mdicon
          name="chevron-down"
          size="16"
          class="absolute right-2 pointer-events-none"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
