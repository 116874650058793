
import { defineComponent, ref, watch } from 'vue';
import CardEmergency from '@/components/general/molecules/CardEmergency.vue';
import { useRouter, useRoute } from 'vue-router';
import Header from '@/components/general/molecules/Header.vue';
export default defineComponent({
    name: 'simulacrum',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isVisible = ref(props.visible);
        const router = useRouter();
        const route = useRoute();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const emergencyId = ref<number>(typeof route.params.emergencyId === 'string' ? parseInt(route.params.emergencyId) : 0)
        const emergencyTabName = ref<string>(emergencyId.value == 5 ? 'Simulacro' : '')
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/emergency`, name: 'Emergencias' },
            { path: '', name: 'Instrucciones' }
        ]
        const eventDate = [
            { id: 1, icon: 'fire', title: 'INCENDIO', styleCard: 'bg-danger ' },
            { id: 2, icon: 'tsunami', title: 'TSUNAMI', styleCard: 'bg-info' },
            { id: 3, icon: 'earth-off', title: 'TERREMOTO', styleCard: 'bg-orangeE' },
            { id: 4, icon: 'exit-run', title: 'EVACUACIóN', styleCard: 'bg-success' },
        ]
        watch(() => props.visible, (newValue) => {
            isVisible.value = newValue;
        });

        const activeTypeSimulacrum = (simulacrumId: any) => {

            switch (simulacrumId) {
                case 1:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${simulacrumId}` });
                    break;
                case 2:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${simulacrumId}` });
                    break;
                case 3:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${simulacrumId}` });
                    break;
                case 4:
                    router.push({ path: `/community/${communityId.value}/block/${blockId.value}/emergency/${emergencyId.value}/alert/${simulacrumId}` });
                    break;
            }
        }


        return { isVisible, eventDate, breadcrumbRoutes, activeTypeSimulacrum, emergencyTabName };
    },

    components: { CardEmergency, Header },
});
