import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex flex-col h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_AdminBar = _resolveComponent("AdminBar")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Header, { breadcrumbRoutes: _ctx.breadcrumbRoutes }, null, 8, ["breadcrumbRoutes"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdminBar)
    ])
  ]))
}