import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex-col md:flex-row w-full md:w-full p-4 h-full items-stretch flex flex-wrap justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      breadcrumbRoutes: _ctx.breadcrumbRoutes,
      hasBack: true
    }, null, 8, ["breadcrumbRoutes"]),
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.committeDate, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "w-full md:w-1/3 p-2 flex"
        }, [
          _createVNode(_component_CardEmergency, {
            size: 17,
            onClick: ($event: any) => (_ctx.goEventModal(item.id)),
            class: "cursor-pointer flex-grow",
            figure: item.figure,
            title: item.name,
            isHome: true
          }, null, 8, ["onClick", "figure", "title"])
        ]))
      }), 128))
    ])
  ]))
}