
import { defineComponent, ref } from "vue";
import { CommunityService } from "@/services/communities/CommunityServices";
import { useRoute, useRouter } from "vue-router";
import CardEmergency from "@/components/general/molecules/CardEmergency.vue";
import InsuranceModal from "../../components/queries/molecules/InsuranceModal.vue";
import InsuranceAddModal from "@/components/queries/molecules/InsuranceAddModal.vue";
import { useAuthStore } from "@/stores/auth";
import Header from "@/components/general/molecules/Header.vue";
export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    const authUser = authStore.user;
    const authUserCommunity = authStore.userCommunity;
    const router = useRouter();
    const route = useRoute();
    const communityId = ref<number>(
      typeof route.params.community === "string" ? parseInt(route.params.community) : 0
    );
    const blockId = ref<number>(
      typeof route.params.id === "string" ? parseInt(route.params.id) : 0
    );
    const showEventModal = ref<boolean>();
    const communityService = new CommunityService();
    const insuranceSelect = ref<object>();
    const insurance = ref();
    const loading = ref<boolean>(true);
    const addInsurance = ref<boolean>(false);
    const action = ref<string>("");
    const breadcrumbRoutes = [
      {
        path: `/community/${communityId.value}/block/${blockId.value}/home`,
        name: "Menú principal",
      },
      {
        path: `/community/${communityId.value}/block/${blockId.value}/queries`,
        name: "Consultas",
      },
      { path: ``, name: "Seguros" },
    ];

    async function getInsurance() {
      await communityService
        .getInsurance(communityId.value)
        .then((response) => {
          if (response.success) {
            insurance.value = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      loading.value = false;
    }
    getInsurance();

    const goEventModal = (item: any) => {
      insuranceSelect.value = item;
      showEventModal.value = true;
    };

    const handleCloseModal = () => {
      showEventModal.value = false;
    };
    const add = () => {
      action.value = "create";
      addInsurance.value = true;
    };
    const change = () => {
      addInsurance.value = false;
      getInsurance();
    };

    return {
      insurance,
      goEventModal,
      change,
      loading,
      insuranceSelect,
      showEventModal,
      addInsurance,
      action,
      breadcrumbRoutes,
      authUser,
      authUserCommunity,
      handleCloseModal,
      add,
    };
  },
  components: { CardEmergency, InsuranceModal, InsuranceAddModal, Header },
});
