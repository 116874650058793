export enum EnvNames {
  PROD = "production",
  DEV = "development",
  TEST = "test",
}
type EnvName = EnvNames.PROD | EnvNames.DEV | EnvNames.TEST;

export interface EnvConfig {
  apiURL: string;
  env: EnvName;
}

const ENV_CONFIGS: Record<EnvName, EnvConfig> = {
  production: {
    apiURL: "https://api.beepi.cl",
    env: EnvNames.PROD,
  },
  development: {
    //apiURL: "https://apibeepi.brics21.cl",
       apiURL: "http://127.0.0.1:8000",
    env: EnvNames.DEV,
  },
  test: {
    apiURL: "http://127.0.0.1:8000",
    env: EnvNames.TEST,
  },
};

export function getEnvConfig(): EnvConfig {
  const nodeEnv = process.env.NODE_ENV as EnvName;
  return ENV_CONFIGS[nodeEnv];
}
