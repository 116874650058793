import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "max-w-full bg-white rounded-lg" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { class: "flex flex-wrap justify-center mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      breadcrumbRoutes: _ctx.breadcrumbRoutes,
      hasBack: true
    }, null, 8, ["breadcrumbRoutes"]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventDate, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: _normalizeClass({ 'w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2': index < 4, 'w-full': index >= 4 })
            }, [
              _createVNode(_component_CardEmergency, {
                size: 200,
                styleCard: item.styleCard,
                class: "m-2 sm:m-4 hover:scale-105 cursor-pointer",
                icon: item.icon,
                title: item.title,
                onClick: ($event: any) => (_ctx.activeTypeSimulacrum(item.id))
              }, null, 8, ["styleCard", "icon", "title", "onClick"])
            ], 2))
          }), 128))
        ])
      ])
    ])
  ]))
}