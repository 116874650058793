import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col h-full"
}
const _hoisted_2 = { class: "flex h-full w-full items-center justify-center" }
const _hoisted_3 = { class: "flex-col md:flex-row w-full md:w-full p-4 flex flex-wrap justify-center items-center h-full gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!
  const _component_CoOwnerShipLawModal = _resolveComponent("CoOwnerShipLawModal")!

  return (
      _ctx.authUserCommunity?.userRole === 2 ||
      _ctx.authUserCommunity?.userRole === 3 ||
      _ctx.authUserCommunity?.userRole === 1
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header, { breadcrumbRoutes: _ctx.breadcrumbRoutes }, null, 8, ["breadcrumbRoutes"]),
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("section", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventTutorial, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "flex-1 p-2 flex h-2/5 gap-4"
              }, [
                _createVNode(_component_CardEmergency, {
                  size: 15,
                  styleCard: item.styleCard,
                  class: "cursor-pointer",
                  figure: item.figure,
                  title: item.title,
                  onClick: ($event: any) => (_ctx.nexPageTutorial(item.id))
                }, null, 8, ["styleCard", "figure", "title", "onClick"])
              ]))
            }), 128))
          ])
        ]),
        (_ctx.ShowFlatModal)
          ? (_openBlock(), _createBlock(_component_CoOwnerShipLawModal, {
              key: 0,
              visible: _ctx.ShowFlatModal,
              onCloseModal: _ctx.handleCloseModal
            }, null, 8, ["visible", "onCloseModal"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}