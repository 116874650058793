import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap flex-row w-full h-full items-center rounded-lg shadow-md border border-gray-300 md:p-1 gap-4 justify-center" }
const _hoisted_2 = { class: "flex-1 h-full items-center justify-center lg:max-h-16 md:max-h-11 max-w-[4rem] md:max-w-[5rem] sm:p-1 p-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex w-4/6 h-full items-center md:p-1 p-2" }
const _hoisted_5 = { class: "flex mt-2 md:text-xs lg:text-sm xl:text-2xl text-xs font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("figure", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/' + _ctx.icon),
        class: "object-contain w-full h-full items-center justify-center"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}