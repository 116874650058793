
import TextField from '@/components/general/atoms/TextField.vue';
import { computed, defineComponent, ref } from 'vue';
import { validateEmailStructure, validateRut, checkPasswords } from '@/services/auth/AuthUtils'
import { AuthService } from '@/services/auth/AuthServices';
import ComponentCard from '@/components/general/molecules/ComponentCard.vue';
import { assertAccessor } from '@babel/types';
import Alert from '@/components/general/atoms/Alert.vue';
import router from '@/router';

export default defineComponent({
    setup() {

        const hasError = ref(false)
        const hasRutError = ref(false)
        const hasPassError = ref(false)
        const hasMailError = ref(false)
        const loading = ref(false);

        const errorMessage = ref("")
        const firstName = ref("")
        const lastName = ref("")
        const rut = ref("")
        const phone = ref("")
        const email = ref("")
        const password = ref("")
        const rePassword = ref("")
        const authServices = new AuthService()

        const badEmail = ref(false)
        const badRut = ref(false)
        const badPasswords = ref(false)
        const emptyFields = ref(false)
        const showAlert = ref<boolean>(false);
        const alertType = ref<string>('')
        const showAlertMessage = ref<string>('')

        async function createAccount() {

            loading.value = true;
            const isValidated = runValidations()
            if (isValidated) {
                const userInfo = {
                    "first_name": firstName.value,
                    "last_name": lastName.value,
                    "rut": rut.value,
                    "phone": phone.value,
                    "email": email.value,
                    "password": password.value,
                    "re_password": rePassword.value,
                }
                const response = await authServices.register(userInfo)

                if (response.code == 404) {
                    showAlertMessage.value = 'El Email ingresado ya existe, Intenta con otro Email.';
                    alertType.value = 'error';
                    showAlert.value = true;
                    loading.value = false;
                }
                if (300 > response.code && response.code >= 200) {
                    alert('El usuario se ha registrado sin problemas.')
                    loading.value = false;
                    router.push({ name: 'login' })
                }
            }
        }

        function runValidations() {

            let valid = true
            if (!checkPasswords(password.value, rePassword.value)) {
                valid = false
                badPasswords.value = true
                hasPassError.value = true
            }
            else {
                hasPassError.value = false
            }
            if (!validateEmailStructure(email.value)) {
                valid = false
                badEmail.value = true
                hasMailError.value = true
            }
            else {
                hasMailError.value = false
            }
            if (!validateRut(rut.value)) {
                valid = false
                badRut.value = true
                hasRutError.value = true
            }
            else {
                hasRutError.value = false
            }
            if (!firstName.value || !lastName.value || !rut.value || !phone.value || !email.value || !password.value || !rePassword.value) {
                emptyFields.value = true
                valid = false
                hasError.value = true
            }
            else {
                hasError.value = false
                emptyFields.value = false
            }
            return valid
        }
        return {
            hasError,
            firstName,
            lastName,
            rut,
            loading,
            phone,
            email,
            password,
            rePassword,
            badEmail,
            badRut,
            badPasswords,
            errorMessage,
            emptyFields,
            hasRutError,
            hasPassError,
            hasMailError,
            createAccount,
            showAlert,
            alertType,
            showAlertMessage
        };
    },
    components: { TextField, ComponentCard, Alert },
    watch: {
        'rut': {
            handler: function (newvalue, oldvalue) {
                if (!validateRut(newvalue)) {
                    this.badRut = true
                    this.hasRutError = true
                }
                else {
                    this.badRut = false
                    this.hasRutError = false
                }
            },
        },
        'email': {
            handler: function (newvalue, oldvalue) {
                if (!validateEmailStructure(newvalue)) {
                    this.badEmail = true
                    this.hasMailError = true
                }
                else {
                    this.badEmail = false
                    this.hasMailError = false
                }
            },
        },
        'password': {
            handler: function (newvalue, oldvalue) {
                if (!checkPasswords(newvalue, this.rePassword)) {
                    this.badPasswords = true
                    this.hasPassError = true
                }
                else {
                    this.badPasswords = false
                    this.hasPassError = false
                }
            },
        },
        'rePassword': {
            handler: function (newvalue, oldvalue) {
                if (!checkPasswords(newvalue, this.password)) {
                    this.badPasswords = true
                    this.hasPassError = true
                }
                else {
                    this.badPasswords = false
                    this.hasPassError = false
                }
            },
        },
    }
})
