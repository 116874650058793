
import { defineComponent, ref, } from 'vue';
import { useRoute } from 'vue-router';
import InstructionPersonal from '@/instructionsPersonal.json';
import Header from '@/components/general/molecules/Header.vue';
export default defineComponent({

    setup(props, { emit }) {

        const route = useRoute();
        const blockId = ref<number>(typeof route.params.id === 'string' ? parseInt(route.params.id) : 0)
        const communityId = ref<number>(typeof route.params.community === 'string' ? parseInt(route.params.community) : 0)
        const emergencyId = ref<number>(typeof route.params.emergencyId === 'string' ? parseInt(route.params.emergencyId) : 0)
        const emergencyTypeId = ref<number>(typeof route.params.emergencyTypeId === 'string' ? parseInt(route.params.emergencyTypeId) : 0)
        const instructionName = ref<string>(emergencyTypeId.value === 1 ? 'Incendio' : emergencyTypeId.value === 2 ? 'Terremoto'
            : emergencyTypeId.value === 3 ? 'Tsunami' : 'Evacuacion')
        const emergencyTabName = ref<string>(emergencyId.value == 5 ? 'Simulacro' : '')
        const breadcrumbRoutes = [
            { path: `/community/${communityId.value}/block/${blockId.value}/home`, name: 'Menú principal' },
            { path: `/community/${communityId.value}/block/${blockId.value}/emergency`, name: 'Emergencias' },
            { path: '', name: 'Instrucciones' }
        ]

        const getInstructionsByEmergencyId = (emergencyId: number) => {
            return InstructionPersonal.filter(instruction => instruction.emergency_type_id === emergencyId);
        };

        return {
            emergencyId, breadcrumbRoutes, instructionName, InstructionPersonal, emergencyTypeId, getInstructionsByEmergencyId, emergencyTabName
        };
    },

    components: {Header},
});
