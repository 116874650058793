import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col h-full"
}
const _hoisted_2 = { class: "flex flex-row gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Header = _resolveComponent("Header")!
  const _component_CardEmergency = _resolveComponent("CardEmergency")!
  const _component_VideoModal = _resolveComponent("VideoModal")!
  const _component_TutorialViewModal = _resolveComponent("TutorialViewModal")!
  const _component_ModalAlert = _resolveComponent("ModalAlert")!

  return (
      _ctx.authUserCommunity?.userRole === 2 ||
      _ctx.authUserCommunity?.userRole === 3 ||
      _ctx.authUserCommunity?.userRole === 1
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header, {
          breadcrumbRoutes: _ctx.breadcrumbRoutes,
          hasBack: true
        }, {
          body: _withCtx(() => [
            _createElementVNode("button", {
              class: "flex bg-success text-white rounded-lg p-1.5 hover:bg-green-600",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openShowVideo('create', '')))
            }, [
              _createElementVNode("strong", _hoisted_2, [
                _createVNode(_component_mdicon, { name: "youtube" }),
                _createTextVNode(" Agregar Tutorial ")
              ])
            ])
          ]),
          _: 1
        }, 8, ["breadcrumbRoutes"]),
        _createElementVNode("section", {
          class: _normalizeClass(["flex-col md:flex-row w-full md:w-full p-4 h-full items-stretch flex flex-wrap justify-center", _ctx.videoTutorial.length <= 5 ? 'h-2/6' : 'h-full'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoTutorial, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "w-full sm:w-[20%]flex"
            }, [
              _createVNode(_component_CardEmergency, {
                class: "cursor-pointer flex-grow sm:w-[20%]",
                figure: 'tutorials/movie',
                title: item.name,
                size: 15,
                deleteItem: true,
                itemId: item.id,
                onHandDelete: _ctx.deleteItem,
                onClick: ($event: any) => (_ctx.showVideoTutorial(item))
              }, null, 8, ["title", "itemId", "onHandDelete", "onClick"])
            ]))
          }), 128))
        ], 2),
        (_ctx.ShowVideoModal)
          ? (_openBlock(), _createBlock(_component_VideoModal, {
              key: 0,
              action: _ctx.action,
              tutorialType: 'TRAINING_VIDEOS',
              videoDate: _ctx.videoDataSend,
              visible: _ctx.ShowVideoModal,
              onCloseModal: _ctx.closeModal
            }, null, 8, ["action", "videoDate", "visible", "onCloseModal"]))
          : _createCommentVNode("", true),
        (_ctx.showViewTutorialModal)
          ? (_openBlock(), _createBlock(_component_TutorialViewModal, {
              key: 1,
              videoData: _ctx.videoDataSend,
              visible: _ctx.showViewTutorialModal,
              onCloseModal: _ctx.closeModal,
              onEditVideo: _ctx.openShowVideo
            }, null, 8, ["videoData", "visible", "onCloseModal", "onEditVideo"]))
          : _createCommentVNode("", true),
        (_ctx.success)
          ? (_openBlock(), _createBlock(_component_ModalAlert, {
              key: 2,
              title: _ctx.title,
              message: _ctx.message,
              visible: _ctx.success,
              option: false,
              onAccept: _ctx.handleAcceptSuccess,
              onCancelAlert: _ctx.handleCancelSuccess
            }, null, 8, ["title", "message", "visible", "onAccept", "onCancelAlert"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}